// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-full-js": () => import("./../../../src/pages/blog-full.js" /* webpackChunkName: "component---src-pages-blog-full-js" */),
  "component---src-pages-blog-grid-js": () => import("./../../../src/pages/blog-grid.js" /* webpackChunkName: "component---src-pages-blog-grid-js" */),
  "component---src-pages-blog-list-js": () => import("./../../../src/pages/blog-list.js" /* webpackChunkName: "component---src-pages-blog-list-js" */),
  "component---src-pages-blog-single-js": () => import("./../../../src/pages/blog-single.js" /* webpackChunkName: "component---src-pages-blog-single-js" */),
  "component---src-pages-home-slider-js": () => import("./../../../src/pages/home-slider.js" /* webpackChunkName: "component---src-pages-home-slider-js" */),
  "component---src-pages-home-two-js": () => import("./../../../src/pages/home-two.js" /* webpackChunkName: "component---src-pages-home-two-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-one-js": () => import("./../../../src/pages/index-one.js" /* webpackChunkName: "component---src-pages-index-one-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */)
}

